<template>
	<ManageYourAccountTemplate :page-title="$t('title')">
		<ChangePasswordForm
			:value="user"
			:automation-id="getAutomationId('changePassword')"
			:previous-password-error="$t('error.previousPassword')"
			:show-previous-password-error="isPreviousPassword"
			@input="updateUser"
			@submit="updatePassword()"
		/>
	</ManageYourAccountTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import ChangePasswordForm from '@/components/manage-your-account/change-password/ChangePasswordForm.vue';
import MemberCardInfo from '@/models/MemberCardInfo';
import * as Routes from '@/constants/Routes';
import ManageYourAccountTemplate from '../ManageYourAccountTemplate.vue';

// @vue/component
// This screen is the used in the my account section for a member to change their password.
// If a member has forgotten their password, and tries the forgot password flow that screen is located here:
// /src/pages/password/ChangePassword.vue
@Component({
	name: 'ChangePassword',
	components: {
		ChangePasswordForm,
		ManageYourAccountTemplate
	},
	mixins: [IdMixin, BreadcrumbsManager]
})
export default class ChangePassword extends Vue {
	user = {
		current: '',
		newPassword: '',
		confirm: ''
	};
	isSuccess = false;
	isPreviousPassword = false;
	// Set the path for the BreadCrumbs Manager.
	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'en'), to: { name: Routes.MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.personal', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'fr'), to: { name: Routes.MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.personal', 'fr') }
			]
		);
	}

	updateUser(user) {
		this.user = user;
	}

	async updatePassword() {
		this.$store.dispatch('updateLoading', true);
		this.isPreviousPassword = false;
		let response = await MemberCardInfo.updateUserPassword(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			this.user.current,
			this.user.newPassword
		);
		if (response && response.status <= 204) {
			const securityPolicy = sessionStorage.getItem('securityPolicy');
			const brand = JSON.parse(securityPolicy).brand ? JSON.parse(securityPolicy).brand : 'MBC';
			this.$router.push({ name: Routes.CHANGE_PASSWORD_SUCCESS, query: { brand: brand } });
		} else if (response?.data.message === 'INVALID') {
			this.$store.dispatch('error', { name: 'changePassword', err: this.$t('error.invalid') });
		} else if (response?.data.message === 'LOCKED') {
			this.$store.dispatch('error', { name: 'changePassword', err: this.$t('error.locked') });
		} else if (response && response.status === 409) {
			this.isPreviousPassword = true;
		} else {
			this.$store.dispatch('error', { name: 'changePassword', err: response?.data?.message });
		}
		this.$store.dispatch('updateLoading', false);
	}
}
</script>

<i18n>
{
  "en": {
    "title": "Account Information",
    "breadcrumb": {
      "home": "Home",
      "memberInfo": "Manage your account",
      "personal": "Change password"
    },
	"error": {
		"invalid": "Sorry, your login information is incorrect. Please try again.",
		"locked": "For security purposes, your account has been temporarily locked. Please try again later.",
		"previousPassword": "Password cannot be the same as your last 5 passwords."
	}
  },
  "fr": {
    "title": "Renseignements sur le compte",
    "breadcrumb": {
      "home": "Accueil",
      "memberInfo": "Gérer votre compte",
      "personal": "Changer le mot de passe"
    },
	"error": {
		"invalid": "Désolés, vos informations de connexion sont erronées. Veuillez réessayer.",
		"locked": "Pour des raisons de sécurité, votre compte est temporairement bloqué. Veuillez réessayer plus tard.",
		"previousPassword": "Le mot de passe ne peut pas être le même que les cinq derniers utilisés."
	}
  }
}
</i18n>
