<template>
	<BaseCard :automation-id="getAutomationId('change-password-form')">
		<BaseForm :automation-id="getAutomationId('changePassword')" @submit="submitPassword()">
			<p class="h3">{{ $t('title.changePassword') }}</p>
			<p class="mb-0 email-label mt-3 pb-0">{{ $t('title.emailConnect') }}</p>
			<p>{{ userEmail }}</p>
			<div class="content">
				<PasswordInput
					:input="localValue.current"
					:label="$t('input.current')"
					:min="null"
					class="password-input"
					:required-message="$t('error.oldRequired')"
					:automation-id="getAutomationId('changePasswordOld')"
					@input="updateCurrent"
				/>
				<PasswordRequirements :automation-id="getAutomationId('changePassword')" />
				<template v-if="showPreviousPasswordError">
					<BaseAlert variant="danger" :icon="['fal', 'exclamation-triangle']">
						{{ previousPasswordError }}
					</BaseAlert>
				</template>
				<PasswordInput
					:input="localValue.newPassword"
					vid="newChangePassword"
					:label="$t('input.new')"
					require-complexity
					:required-message="$t('error.newRequired')"
					class="password-input"
					:automation-id="getAutomationId('changePasswordNew')"
					@input="updateNewPassword"
				/>
				<ConfirmInput
					v-model="localValue.confirm"
					:label="$t('input.confirm')"
					:field="$t('input.new')"
					:confirmation="localValue.newPassword"
					password
					class="password-input"
					:automation-id="getAutomationId('changePasswordConfirm')"
				/>
			</div>
			<div>
				<BaseButton
					:label="$t('button.save')"
					variant="primary"
					type="submit"
					:automation-id="getAutomationId('changePasswordSave')"
				/>
				<BaseButton
					:label="$t('button.cancel')"
					variant="link"
					:automation-id="getAutomationId('changePasswordCancel')"
					@click="toMemberInfo()"
				/>
			</div>
		</BaseForm>
	</BaseCard>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseForm from '@/components/common/base/BaseForm';
import BaseCard from '@/components/common/card/BaseCard';
import PasswordRequirements from '@/components/common/PasswordRequirements';
import PasswordInput from '@/components/common/PasswordInput';
import ConfirmInput from '@/components/common/ConfirmInput';
import BaseButton from '@/components/common/base/BaseButton';
import * as Routes from '@/constants/Routes';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
// @vue/component
// This is the change password form for when the member logs in and wants to change their password in the my account section.
@Component({
	name: 'ChangePasswordForm',
	components: {
		BaseCard,
		BaseForm,
		PasswordRequirements,
		PasswordInput,
		ConfirmInput,
		BaseButton,
		BaseAlert
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		previousPasswordError: {
			type: String,
			default: null
		},
		showPreviousPasswordError: {
			type: Boolean,
			default: false
		}
	}
})
export default class ChangePasswordForm extends Vue {
	get localValue() {
		return this.value ? this.value : {};
	}

	get userEmail() {
		return sessionStorage.getItem('email');
	}

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	submitPassword() {
		this.$emit('submit');
	}

	updateCurrent(current) {
		this.$emit('input', { ...this.value, current });
	}

	updateNewPassword(newPassword) {
		this.$emit('input', { ...this.value, newPassword });
	}

	toMemberInfo() {
		this.$router.push({ name: Routes.MANAGE_YOUR_ACCOUNT });
	}
}
</script>
<style lang="scss" scoped>
.small-body {
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
	font-size: $body-small-font-size;
}
.email-label {
	font-family: $josefin-font-family;
	font-size: $body-regular-bold-font-size;
}
.password {
	max-width: 318px;
}
.col-padding {
	padding: 30px;
	@media (max-width: 576px) {
		padding: 24em;
	}
}
.content ::v-deep .btn {
	@media (max-width: 320px) {
		margin-top: 23px;
	}
}
.content {
	width: 60%;
	@media (max-width: 920px) {
		width: 100%;
	}
}
.password-input ::v-deep label {
	@media (max-width: 320px) {
		width: 45%;
	}
}
</style>
<i18n>
{
    "en": {
        "title": {
        	"changePassword": "Change password",
        	"emailConnect": "Email connected to this account:"
        },
        "input": {
            "current": "Current password",
            "new": "New password",
            "confirm": "Confirm new password"
        },
        "button": {
            "save": "Save",
            "cancel": "Cancel"
				},
				"error": {
					"oldRequired": "Please enter your current password.",
					"newRequired": "Please enter a new password."
				}
    },
    "fr": {
        "title": {
            "changePassword": "Changer le mot de passe",
            "emailConnect": "Adresse courriel associée à ce compte :"
        },
        "input": {
            "current": "Ancien mot de passe",
            "new": "Mot de passe",
            "confirm": "Confirmez le nouveau mot de passe"
        },
        "button": {
            "save": "Enregistrer",
            "cancel": "Annuler"
				},
								"error": {
					"oldRequired": "Veuillez saisir votre mot de passe actuel.",
					"newRequired": "Veuillez saisir un nouveau mot de passe."
				}
    }
}
</i18n>
