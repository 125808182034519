<template>
	<Result
		:automation-id="getAutomationId('successRegistration')"
		:title="$t('success.title')"
		:sub-title="$t('success.subTitle')"
		:content="$t('success.content')"
		icon-color="success-color"
		:button="$t('success.button')"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result.vue';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'SuccessChangePassword',
	components: {
		Result
	},
	mixins: [IdMixin]
})
export default class SuccessChangePassword extends Vue {
	mounted() {
		this.$store.dispatch('clearError');
		sessionStorage.clear();
	}
}
</script>

<i18n>
{
    "en": {
        "success": {
            "title": "Success!",
            "subTitle": "Your password has been successfully changed.",
            "content": "You can now log in with your new password.",
            "button": "Go to login"
        }
    },
    "fr": {
        "success": {
            "title": "Succès !",
            "subTitle": "Votre mot de passe a bien été changé.",
            "content": "Vous pouvez maintenant ouvrir une session avec votre nouveau mot de passe.",
            "button": "Aller à la page de connexion"
        }
    }
}
</i18n>
